import React, { useEffect, useRef, useState } from 'react'
import lottie from 'lottie-web'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import cn from 'classnames'
import {
	__,
	anyPass,
	always,
	cond,
	compose,
	equals,
	intersection,
	T,
	innerJoin,
	flip,
	propEq,
} from 'ramda'

import { useContextState, useContextDispatch } from '../../context/ContextProvider'

import dicussionAnimation from '../../animations/dicussion-animation.json'
import developmentAnimation from '../../animations/development-animation.json'
import warrantyAnimation from '../../animations/warranty-animation.json'
import designStepVideo from '../../video/design-step-video.mp4'

import Icon from '../Icon'
import PhoneAnim from '../PhoneAnim'

import './DevSteps.css'

import Modal from '../Modal'
import ContactForm from '../ContactForm'

import Select from 'react-select'

//Notification
import Notification from '../Notification'
import * as styles from '../Notification/Notification.module.css'

import presentationIcon from '../../images/presentation.svg'
import presentationDownloadIcon from '../../images/presentation-download.svg'
import presentationFile from '../../docs/presentation.pdf'
import DevReady from '../DevReady'
import PhoneImage from '../PhoneImage'

const SpecMsg = always({
	term: '0,5 - 1 неделя',
	price: '30–100 тыс',
})
const DesignMsg = always({
	term: '1 – 2 недели',
	price: '80 – 200 тыс',
})
const DevelopMsg = always({
	term: '4 – 8 недель',
	price: '600 тыс – 1.5 млн',
})
const SpecAndDesignMsg = always({
	term: '1,5 – 3 недели',
	price: '110 – 300 тыс',
})
const SpecAndDevelopMsg = always({
	term: '4.5 – 9 недель',
	price: '630 тыс. – 1.6 млн',
})
const DesignAndDevelopMsg = always({
	term: '5 – 10 недель',
	price: '680 тыс – 1.8 млн',
})
const AllSelectedMsg = always({
	term: '5,5 – 11 недель',
	price: '700 тыс – 1.8 млн',
})

const keyServices = ['development', 'design', 'specification']
const matchedWithKeyServices = intersection(keyServices)
const isOnlySpec = compose(equals(['specification']), matchedWithKeyServices)
const isOnlyDesign = compose(equals(['design']), matchedWithKeyServices)
const isOnlyDevelop = compose(equals(['development']), matchedWithKeyServices)
const isSpecAndDesign = compose(equals(['design', 'specification']), matchedWithKeyServices)
const isSpecAndDevelop = compose(equals(['development', 'specification']), matchedWithKeyServices)
const isDesignAndDevelop = compose(equals(['development', 'design']), matchedWithKeyServices)
const isAllSelected = compose(equals(['development', 'design', 'specification']), matchedWithKeyServices)

const isAtListOneChoosen = anyPass([
	isOnlySpec,
	isOnlyDesign,
	isOnlyDevelop,
	isSpecAndDesign,
	isSpecAndDevelop,
	isDesignAndDevelop,
	isAllSelected,
])

const getCalculationMsg = cond([
	[isOnlySpec, SpecMsg],
	[isOnlyDesign, DesignMsg],
	[isOnlyDevelop, DevelopMsg],
	[isSpecAndDesign, SpecAndDesignMsg],
	[isSpecAndDevelop, SpecAndDevelopMsg],
	[isDesignAndDevelop, DesignAndDevelopMsg],
	[isAllSelected, AllSelectedMsg],
	[T, () => null],
])

const DevSteps = () => {
	const notificationInside = useRef(null)
	const ref = useRef(null)
	const dicussionRef = useRef(null)
	const developmentRef = useRef(null)
	const warrantyRef = useRef(null)
	const { services, persistentServices } = useContextState()
	const dispatch = useContextDispatch()
	const [open, setOpen] = useState(false)
	const handleClickOpen = () => setOpen(true)
	const handleClickClose = () => setOpen(false)

	const getDefaultOptions = innerJoin(flip(propEq('value')))

	const options = [
		{ value: 'prototype', label: 'Прототип' },
		{ value: 'specification', label: 'Аналитика и техническое задание' },
		{ value: 'design', label: 'Дизайн' },
		{ value: 'development', label: 'Разработка полного цикла' },
		{ value: 'publication', label: 'Доработка/поддержка' },
	]

	let optionsArr = []
	for (let i = 0; i < services.length; i++) {
		for (let j = 0; j < options.length; j++) {
			if (services[i] == options[j].value) {
				optionsArr.push({ value: services[i], label: options[j].label })
			}
		}
	}

	const Steps = React.forwardRef(({ value, onChange, defaultValues = services }, ref) => {
		const styles = {
			control: (provided) => ({
				...provided,
				minHeight: '48px',
				borderRadius: '8px',
				borderColor: '#d4e0eb',
				boxShadow: 'none',

				'&:hover': {
					borderColor: '#d4e0eb',
				},
			}),
		}
		defaultValues = optionsArr
		const defaultOptions = getDefaultOptions(optionsArr, defaultValues)

		const _onChange = () => {
			onChange(defaultOptions)
		}

		return (
			<Select
				defaultValue={defaultValues}
				options={options}
				placeholder=""
				isMulti
				isClearable={false}
				isSearchable={false}
				onChange={_onChange}
				styles={styles}
			/>
		)
	})

	const { prototypeStepMedia, publishStepMedia, specsStepMedia,  testingStepMedia, pubStepMedia, promotingStepMedia } = useStaticQuery(graphql`
		query {
			protoStepMedia: file(relativePath: { eq: "prototype-step-media.png" }) {
				childImageSharp {
					fluid(maxWidth: 298, quality: 100) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			specStepMedia: file(relativePath: { eq: "specification-step-media.svg" }) {
				publicURL
			}
			specsStepMedia: file(relativePath: { eq: "devsteps-specs.png" }) {
				childImageSharp {
					fluid(maxWidth: 532, quality: 100) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			publishStepMedia: file(relativePath: { eq: "devsteps-publishing.png" }) {
				childImageSharp {
					fluid(maxWidth: 532, quality: 100) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			prototypeStepMedia: file(relativePath: { eq: "prototype-step-media.svg" }) {
				publicURL
			}
			testingStepMedia: file(relativePath: { eq: "testing-step-media.png" }) {
				childImageSharp {
					fluid(maxWidth: 532, quality: 100) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			pubStepMedia: file(relativePath: { eq: "publication-step-media.png" }) {
				childImageSharp {
					fluid(maxWidth: 532, quality: 100) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			promotingStepMedia: file(relativePath: { eq: "promoting-step-media.svg" }) {
				publicURL
			}
		}
	`)

	useEffect(() => {
		lottie.loadAnimation({
			container: dicussionRef.current,
			animationData: dicussionAnimation,
			renderer: 'svg',
			loop: true,
			autoplay: true,
		})

		lottie.loadAnimation({
			container: developmentRef.current,
			animationData: developmentAnimation,
			renderer: 'svg',
			loop: true,
			autoplay: true,
		})

		lottie.loadAnimation({
			container: warrantyRef.current,
			animationData: warrantyAnimation,
			renderer: 'svg',
			loop: true,
			autoplay: true,
		})
	}, [])

	const isAlreadyChecked = (id) => services.includes(id)
	const isStepPersistent = (id) => persistentServices.includes(id)

	const onStepClick = (id) => {
		if (isAlreadyChecked(id) && !isStepPersistent(id)) {
			dispatch({ type: 'REMOVE_SERVICE', payload: id })
			return
		}

		if (isStepPersistent(id)) return

		dispatch({ type: 'ADD_SERVICE', payload: id })
	}

	// const renderResult = () => {
	//   const result = getCalculationMsg(services)

	//   if (!result) return

	//   return (
	//     <>
	//       <div className="devsteps-resitem">
	//         <p className="mb-1 text-gray text-sm">Сроки:</p>
	//         <p className="text-sm sm:text-base lg:text-xl text-dark font-bold">{result.term}</p>
	//       </div>
	//       <div className="devsteps-resitem">
	//         <p className="mb-1 text-gray text-sm">Стоимость:</p>
	//         <p className="text-sm sm:text-base lg:text-xl text-primary font-bold">{result.price}</p>
	//       </div>
	//     </>
	//   )
	// }

	return (
		<>
			{/* className={cn('devsteps container', { devsteps_active: isAtListOneChoosen(services) })} */}
			<section className="devsteps container devsteps_active">
				<div className="devsteps-cover">
					{/* {isAtListOneChoosen(services) &&  */}
					{/*<div className="devsteps-result">*/}
					{/*	<div className="devsteps-resitem">*/}
					{/*		<p className="mb-1 text-gray text-sm">Средние сроки:</p>*/}
					{/*		<p className="text-sm sm:text-base lg:text-xl text-dark font-bold">4 месяца</p>*/}
					{/*	</div>*/}
					{/*	<div className="devsteps-resitem">*/}
					{/*		<p className="mb-1 text-gray text-sm">Средняя стоимость:</p>*/}
					{/*		<p className="text-sm sm:text-base lg:text-xl text-primary font-bold">3 миллиона</p>*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/* } */}
				</div>
				<ul className="devsteps-list">
					<li
						className="devsteps-unit devsteps-unit--discussion"
						onClick={onStepClick.bind(null, 'discussion')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Сбор информации и аналитика</h3>
							<div className="devsteps-content">
								<p>
									Обговариваем все ключевые детали проекта, формируем полное представление о нем. Обмениваемся макетами, идеями, опытом. Анализируем рынок, исследуем конкурентов, обсуждаем стратегию.
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('discussion'),
								'is-persistent': isStepPersistent('discussion'),
							})}
						>
							<Icon name="check" width="34" height="26" className="fill-current" />
						</div>
						<div className="devsteps-media" ref={dicussionRef}></div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--specification"
						id="specification"
						onClick={onStepClick.bind(null, 'specification')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Техническое задание</h3>
							<div className="devsteps-content">
								<p>
									Пишем подробное техническое задание: какие функции будут в мобильном приложении, как это
									будет реализовано на платформах, как платформы будут взаимодействовать с серверной стороной.
								</p>
								<p>
									Рассчитываем необходимую инфраструктуру проекта, рисуем схему переходов между экранами и
									разделами приложения.
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('specification'),
								'is-persistent': isStepPersistent('specification'),
							})}
						>
							{isAlreadyChecked('specification') && (
								<Icon name="check" width="34" height="26" className="fill-current" />
							)}
						</div>
						<div className="devsteps-media">
							<PhoneImage poster={specsStepMedia.childImageSharp.fluid} />
						</div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--prototype"
						onClick={onStepClick.bind(null, 'prototype')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Прототип приложения</h3>
							<div className="devsteps-content">
								<p>
									Для визуализации будущего проекта и обсуждения его с потенциальными пользователями, как
									правило, нужен прототип.
								</p>
								<p>
									Это макет будущего приложения без окончательного дизайна, но с рабочими элементами
									интерфейса.
								</p>
								<a
									className="link link--primary"
									href="https://appcraft.pro/blog/zachem-vam-nuzhen-prototip/"
									target="_blank"
								>
									Подробнее о прототипах
								</a>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('prototype'),
								'is-persistent': isStepPersistent('prototype'),
							})}
						>
							{isAlreadyChecked('prototype') && (
								<Icon name="check" width="34" height="26" className="fill-current" />
							)}
						</div>
						<div className="devsteps-media">
							<img src={prototypeStepMedia.publicURL} alt="" />
						</div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--design"
						id="design"
						onClick={onStepClick.bind(null, 'design')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">UX/UI дизайн интерфейса</h3>
							<div className="devsteps-content">
								<p>На основе технического задания и фирменного стиля заказчика готовим макеты будущих экранов.</p>
								<p>
									Выстраиваем логику пользовательского взаимодействия между элементами (кнопки, формы ввода данных, иконки, слайдеры)
									на основе принципов UX/UI дизайна и требований магазинов приложений (Human Interface Guidelines для App Store и Material Design для Google Play).
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('design'),
								'is-persistent': isStepPersistent('design'),
							})}
						>
							{isAlreadyChecked('design') && (
								<Icon name="check" width="34" height="26" className="fill-current" />
							)}
						</div>
						<div className="devsteps-media">
							<PhoneAnim video={designStepVideo} />
						</div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--development"
						id="development"
						onClick={onStepClick.bind(null, 'development')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Разработка ПО</h3>
							<div className="devsteps-content">
								<p>
									Поэтапно двухнедельными спринтами разрабатываем мобильное приложение: проектируем серверную часть, параллельно строя архитектуру самого приложения.
								</p>
								<p>
									Создаем экраны по дизайн-макетам, прорабатываем анимации, проверяем юзабельность элементов на практике.
								</p>
								<p>
									<b>Каждые две недели</b> отправляем версию с новым функционалом для просмотра на вашем устройстве.
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('development'),
								'is-persistent': isStepPersistent('development'),
							})}
						>
							{isAlreadyChecked('development') && (
								<Icon name="check" width="34" height="26" className="fill-current" />
							)}
						</div>
						<div className="devsteps-media" ref={developmentRef}></div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--testing"
						onClick={onStepClick.bind(null, 'testing')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Тестирование</h3>
							<div className="devsteps-content">
								<p>
									Проводим всестороннее тестирование продукта, разными способами проверяя его поведение во
									внештатных ситуациях.
								</p>
								<p>
									Тестируем на разных устройствах в разных условиях, чтобы даже при авиарежиме в самолете
									приложение вело себя корректно.
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('testing'),
								'is-persistent': isStepPersistent('testing'),
							})}
						>
							<Icon name="check" width="34" height="26" className="fill-current" />
						</div>
						<div className="devsteps-media">
							<Img fluid={testingStepMedia.childImageSharp.fluid} />
						</div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--publication"
						onClick={onStepClick.bind(null, 'publication')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Публикация</h3>
							<div className="devsteps-content">
								<p>
									Помогаем создать аккаунты разработчиков в магазинах приложений App Store, Google Play
									и Huawei App Gallery, готовим необходимые графические материалы, консультируем по ASO.
								</p>
								<p>
									По просьбе заказчика отправляем продукт на публикацию, обрабатываем ответы от технической поддержки магазинов приложений в случае возникновения проблем.
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('publication'),
								'is-persistent': isStepPersistent('publication'),
							})}
						>
							{isAlreadyChecked('publication') && (
								<Icon name="check" width="34" height="26" className="fill-current" />
							)}
						</div>
						<div className="devsteps-media">
							{/*<Img fluid={pubStepMedia.childImageSharp.fluid} />*/}
							<PhoneImage poster={publishStepMedia.childImageSharp.fluid} />
						</div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--promoting"
						onClick={onStepClick.bind(null, 'promoting')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Продвижение приложения</h3>
							<div className="devsteps-content">
								<p>
									Разрабатываем и реализовываем маркетинговую стратегию. Проводим комплекс работ
									по оптимизации страниц приложения в сторах, запускаем рекламные кампании, занимаемся контент- и инфлюэнс-маркетингом, привлекаем первые установки.
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('promoting'),
								'is-persistent': isStepPersistent('promoting'),
							})}
						>
							{isAlreadyChecked('promoting') && (
								<Icon name="check" width="34" height="26" className="fill-current" />
							)}
						</div>
						<div className="devsteps-media">
							<img src={promotingStepMedia.publicURL} alt="" />
						</div>
					</li>

					<li
						className="devsteps-unit devsteps-unit--warranty"
						onClick={onStepClick.bind(null, 'warranty')}
					>
						<div className="devsteps-caption">
							<h3 className="devsteps-title">Гарантия на приложение</h3>
							<div className="devsteps-content">
								<p>
									Передаем исходный код и все сопроводительные материалы к продукту, а также права на
									разработанную программу.
								</p>

								<p>
									Осуществляем поддержку и исправляем проблемы, возникшие в процессе использования приложения
									широкой аудиторией.
								</p>
							</div>
						</div>
						<div
							className={cn('devsteps-mark', {
								'is-checked': isAlreadyChecked('warranty'),
								'is-persistent': isStepPersistent('warranty'),
							})}
						>
							<Icon name="check" width="34" height="26" className="fill-current" />
						</div>
						<div className="devsteps-media" ref={warrantyRef}></div>
					</li>
				</ul>
				<DevReady/>
				<div className="devcost">
					<div className="devcost-caption">
						<h2 className="devcost-title text-22 sm:text-28 md:text-42 font-bold leading-tight">
							Сроки и стоимость
						</h2>
						<div className="devcost-description">
							<p>
								Оценка сильно зависит от типа проекта, его цели, используемых технологий и подходов. Здесь мы
								приводим усредненные значения, на которые можно ориентироваться. Точную оценку мы сможем дать,
								узнав детали.
							</p>
							<a
								className="link link--primary"
								href="https://appcraft.pro/blog/stoimost-razrabotki-prilozheniya/"
								target="_blank"
							>
								Подробнее о стоимости приложений
							</a>
						</div>
					</div>
					<div className="devcost-btn_with_presentation">
						<div className="devcost-btn">
							<div className="devcost-btn-block ">
							{/* {isAtListOneChoosen(services) && ( */}
							<button className="btn btn--primary w-full  py-4 sm:px-12" onClick={handleClickOpen}>
								Запросить оценку
							</button>
							{/* <a
								className="btn btn--primary w-full  py-4 sm:px-12"
								href="https://qlick.io/ru/widget/appcraft"
								target="_blank"
								rel="nofollow"
							>
								Уточнить оценку
							</a> */}
							{/* )} */}
						</div>
						</div>
						<div className="devcost-presentation">
							<img className="presentation-image" src={presentationIcon} alt=""/>
							<span>Презентация студии</span>
							<a className="" target={'_blank'} href={presentationFile}>
								<img className="presentation-image" src={presentationDownloadIcon} alt=""/>
							</a>
						</div>
					</div>
					<Modal isOpen={open} onClose={handleClickClose} notificationInside={notificationInside}>
						<div className="px-2">
							<h2 className="mb-6 text-22 sm:text-28 font-bold leading-tight">
								Мы ответим в течение 1 рабочего дня
							</h2>
							<ContactForm Interests={Steps} notificationRef={ref} onClose={handleClickClose} />
						</div>
					</Modal>
					<div className={styles.notifications} ref={notificationInside}>
						<Notification
							children={(add) => {
								ref.current = add
							}}
						/>
					</div>
				</div>
			</section>
		</>
	)
}

export default DevSteps

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Icon from '../Icon'

const DevArticles = () => {
  const {
    allHomedevpostsJson: { edges: articles },
  } = useStaticQuery(graphql`
    query {
      allHomedevpostsJson {
        edges {
          node {
            preview
            readtime
            title
            views
            url
          }
        }
      }
    }
  `)

  const renderArticles = articles.map(({ node: article }, key) => (
    <li className="devarticles-unit" key={key}>
      <article className="card card--article">
        <a className="card-link" href={article.url} target="_blank" rel="noreferrer">
          <span className="sr-only">{article.title}</span>
        </a>
        <h3 className="card-title">{article.title}</h3>
        <p className="card-body">{article.preview}</p>
        <footer className="card-meta">
          <div className="card-metaunit">
            <Icon name="clock" width="14" height="16" className="mr-2 fill-current" />
            <span>{article.readtime}</span>
          </div>
          <div className="card-metaunit">
            <Icon name="eye" width="16" height="14" className="mr-2 fill-current" />
            <span>{article.views}</span>
          </div>
          <div className="card-cta ml-auto">
            <span>Читать</span>
            <Icon name="arrow-right" width="14" height="14" className="card-ctaicon fill-current" />
          </div>
        </footer>
      </article>
    </li>
  ))

  return (
    <section className="devarticles container">
      <h2 className="devarticles-title sm:text-28 font-bold leading-tight">Статьи о разработке</h2>
      <ul className="devarticles-list">{renderArticles}</ul>
    </section>
  )
}

export default DevArticles

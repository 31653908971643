import React, { useRef, useState } from 'react'
import devconsultingImage from '../../images/devconsulting-image.svg'

import Modal from '../Modal'
import ContactForm from '../ContactForm'
import Interests from '../Interests'


import './DevConsulting.css'

const DevConsulting = () => {
	const ref = useRef()
	const notificationInside = useRef(null)

  const [open, setOpen] = useState(false)
	const handleClickOpen = () => setOpen(true)
	const handleClickClose = () => setOpen(false)

 return (
    <>
      <section className="devconsulting container" id="audit-anchor">
        <div className="devconsulting-cover">
          <div className="devconsulting-button-block">
            <h2 className="devconsulting-header-text">Аудит и консалтинг</h2>
            <div className="devconsulting-text">
              <p>
                Если у вас уже есть готовый проект, и вам хочется узнать, как можно сделать его лучше, мы сможем в этом помочь. Расскажите нам о вашем продукте и задачах, которые он должен решать, а мы проведем комплекс работ по аналитике и подготовке рекомендаций, сформируем отчет и обсудим его с вами на встрече в онлайн или офлайн формате.
              </p>
            </div>
            <button className="btn btn--primary w-full  py-4 sm:px-12"  onClick={handleClickOpen}>Заказать аудит</button>
          </div>
          <div className="devconsulting-image-block">
            <img src={devconsultingImage} className="devconsulting-image" alt=""/>
          </div>
        </div>
      </section>
      <Modal isOpen={open} onClose={handleClickClose} notificationInside={notificationInside}>
          <div className="px-2">
            <h2 className="mb-6 text-22 sm:text-28 font-bold leading-tight">
              Мы ответим в течение 1 рабочего дня
            </h2>
            <ContactForm Interests={Interests} notificationRef={ref} onClose={handleClickClose} />
          </div>
        </Modal>
    </>
  )
}

export default DevConsulting

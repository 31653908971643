import React, { useState } from 'react'
import Img from 'gatsby-image'
import cn from 'classnames'

const PhoneAnim = ({ video, poster, className }) => {
  const [isVideoReady, setIsVideoReady] = useState(false)

  const onVideoReady = () => setIsVideoReady(true)

  return (
    <div className={cn('phoneanim', className)}>
      {poster && (
        <Img
          className={cn('phoneanim-poster', { 'is-hidden': isVideoReady })}
          fluid={poster}
          loading="eager"
        />
      )}
      {video && (

        
        <video
          className={cn('phoneanim-video', { 'is-ready': isVideoReady })}
          preload="auto"
          onCanPlay={onVideoReady}
          loop
          muted
          autoPlay
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
    </div>
  )
}

export default PhoneAnim

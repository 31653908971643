import React from 'react'
import HeroMedia from '../../images/devhero-media.inline.svg'
import TipMedia from '../../images/devhero-tip-image.svg'



const DevHero = () => {

  const scrollAnchor = () =>{
    let anchorSection = document.querySelector('#audit-anchor')
    let header =  document.querySelector('.header')
    let anchorPosition = anchorSection.getBoundingClientRect().top - (header.getBoundingClientRect().top + header.getBoundingClientRect().height)
		window.scrollTo({
		  top: anchorPosition, 
		  behavior: 'smooth'
		});
	  };

  return (
    <section className="devhero container">
      <div className="devhero-body">
        <h1 className="devhero-title text-22 sm:text-42 font-bold leading-tight">
          Девять шагов разработки мобильных приложений
        </h1>
        <p className="devhero-subtitle">
          Выбирайте только то, что потребуется именно вам, проставляя галочки в чекбоксах ниже, в зависимости от того, на какой стадии сейчас находится проект
        </p>
        <div className="devhero-tip">
          <img className="devhero-tip-image" src={TipMedia} alt=""/>
          <div className="devhero-tip-text">
            <p>
              Мы также берем проекты на доработку и развитие. Перед началом работ проводим 
              <span className="bold_word" onClick={scrollAnchor}> аудит </span> 
              состояния проекта и даем рекомендации по возможным улучшениям.
            </p>
          </div>
        </div>
      </div>
      <div className="devhero-media">
        <HeroMedia />
      </div>
    </section>
  )
}

export default DevHero

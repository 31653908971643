import React from 'react'
import leftImage from '../../images/devready-left.svg'
import rightImage from '../../images/devready-right.svg'
import awesomeArrow from '../../images/awesome_arrow.svg'

import './DevReady.css'

const DevReady = () => {
  return (
    <>
      <section className="devready container">
        <div className="devready-cover">
          <div className="devready-header">
            <img src={leftImage} className="devready-image" alt=""/>
            <h2 className="devready-header-text">Готово!</h2>
            <img src={rightImage} className="devready-image" alt=""/>
          </div>
          <div className="devready-text">
            <p>
              Отмеченные услуги сохранились в форме обратной связи. Вам осталось
              добавить любой свой контакт и отправить информацию нам.
            </p>
          </div>
          <div className="devready-text">
            <p>
              Мы свяжемся с вами удобным для вас способом и проконсультируем по всем необходимым вопросам.
            </p>
          </div>
          <div className="devready-arrow">
            <img src={awesomeArrow} className="devready-arrow-image" alt=""/>
          </div>
        </div>
      </section>
    </>
  )
}

export default DevReady

import React from 'react'

import Layout from '../components/Layout'
import DevHero from '../components/DevHero'
import DevSteps from '../components/DevSteps'
import DevArticles from '../components/DevArticles'
import DevConsulting from '../components/DevConsulting'

const Services = () => (
  <Layout
    title="Разработка мобильных приложений. Полный цикл"
    content="✅ Разработка мобильных приложений – это аналитика, маркетинг, дизайн, программирование, тестирование и продвижение."
    keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
    ogSiteName="AppCraft"
    ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
    ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
  >
    <DevHero />
    <DevSteps />
    {/*<DevTech />*/}
    <DevConsulting/>

    <DevArticles />
  </Layout>
)

export default Services

import React, { useState } from 'react'
import Img from 'gatsby-image'
import cn from 'classnames'

const PhoneImage = ({ poster, className }) => {
  return (
    <div className={cn('phoneanim', className)}>
      {poster && (
        <Img
          className={cn('phoneanim-poster')}
          fluid={poster}
          loading="eager"
        />
      )}
    </div>
  )
}

export default PhoneImage
